import React from "react";

type Props = {
  img: any;
  title: string;
  description: string;
  inner?: string;
};

const Layer6Item = ({ img, title, description, inner }: Props) => {
  return (
    <div className={`flex flex-row p-5 gap-4 bg-gray col-span-1`}>
      <div className="w-1/2 bg-white">
        <img
          className="object-contain lg:w-[234px] h-full lg:h-[234px]"
          src={`/img/layers/layer6/${img}.png`}
          alt=""
        />
      </div>
      <div className={`w-1/2 flex flex-col text-dark-blue ${inner}`}>
        <h2 className="text-lg lg:text-xl font-semibold">{title}</h2>
        <p className="text-sm lg:text-md mt-3 leading-7">{description}</p>
      </div>
    </div>
  );
};

export default Layer6Item;
