import React from "react";
import ScrollToSection from "../../utils/ScrollToSection";

type Props = {};

const Footer = (props: Props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer
      id="footer"
      className="z-0 bottom-0 bg-[#F9F9F9] lg:h-[453px] w-full"
    >
      <div className="responsive flex flex-col pt-[55px] pb-10 h-full relative">
        <div className="flex items-center flex-col lg:flex-row justify-between">
          <div className="flex flex-col lg:flex-row gap-10 lg:gap-20">
            <address className="flex flex-col font-la">
              <h1 className="text-dark-blue text-xl font-semibold">
                Contact with Us
              </h1>
              <a
                href="mailto:powertsockhub@gmail.com"
                className="text-blue text-md mt-8 lg:mt-16"
                target={"_blank"}
              >
                powertsockhub@gmail.com
              </a>
            </address>

            <address className="flex flex-col">
              <h1 className="text-dark-blue text-xl font-semibold">
                Localization
              </h1>
              <p className="text-black text-md mt-8 lg:mt-16">
                Head office: <br />
                Viru väljak 2 <br />
                Tallinn 10111 <br />
                Estonia
              </p>
            </address>

            <div className="flex flex-col">
              <h1 className="text-dark-blue text-xl font-semibold">Info</h1>
              <ul className="flex flex-col gap-4 text-md text-blue mt-8 lg:mt-10">
                <li onClick={ScrollToSection("home")}>
                  <a
                    href="#home"
                    className="flex items-center place-items-center gap-3 group hover:text-orange hover:cursor-pointer"
                  >
                    <div className="w-4 h-4 bg-blue group-hover:bg-orange rounded-full"></div>{" "}
                    Home
                  </a>
                </li>
                <li>
                  <a className="flex items-center place-items-center gap-3 group hover:text-orange hover:cursor-pointer">
                    <div className="w-4 h-4 bg-blue group-hover:bg-orange rounded-full"></div>{" "}
                    Privacy Policy
                  </a>
                </li>
                <li onClick={ScrollToSection("footer")}>
                  <a
                    href="#footer"
                    className="flex items-center place-items-center gap-3 group hover:text-orange hover:cursor-pointer"
                  >
                    <div className="w-4 h-4 bg-blue group-hover:bg-orange rounded-full"></div>{" "}
                    Contact
                  </a>
                </li>
                <li onClick={ScrollToSection("faq")}>
                  <a
                    href="#faq"
                    className="flex items-center place-items-center gap-3 group hover:text-orange hover:cursor-pointer"
                  >
                    <div className="w-4 h-4 bg-blue group-hover:bg-orange rounded-full"></div>{" "}
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex flex-col gap-4 mt-8 lg:mt-0">
            <div className="w-[97px] h-[97px] bg-blue rounded-full"></div>
            <div className="w-[97px] h-[97px] bg-orange rounded-full"></div>
          </div>
        </div>
        <p className="text-center mt-8 lg:mt-auto text-md">
          © 2024 by <span className="text-blue">Powerstock</span> | All Rights
          Reserved
        </p>
        <svg
          onClick={scrollToTop}
          className="absolute bottom-16 md:bottom-10 right-0 hover:cursor-pointer fill-[#65666C] hover:fill-blue lg:translate-x-20"
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path d="M17.65 14.4001L10.21 7.24012L2.81001 14.4001C2.46334 14.6935 2.13001 14.6868 1.81001 14.3801C1.49001 14.0735 1.49001 13.7468 1.81001 13.4001L9.73001 5.60012C10.0767 5.25345 10.3967 5.25345 10.69 5.60012L18.61 13.4001C18.93 13.7468 18.93 14.0801 18.61 14.4001C18.2633 14.6935 17.9433 14.6935 17.65 14.4001Z" />
        </svg>
      </div>
    </footer>
  );
};

export default Footer;
