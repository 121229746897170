import { createSlice } from "@reduxjs/toolkit";
import { sendEmail } from "./sendEmail";
import { newsletterAPI } from "./newsletterAPI";
import { notify } from "../../utils/notify";

const initialState = {
  sended: false,
  loading: false,
  newsletter: null,
};

const contactSlice = createSlice({
  name: "contactSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendEmail.pending, (state) => {
      state.loading = true;
      console.log("state.loading", state.loading);
    });
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.sended = !!action.payload;
      console.log("action.payload", action.payload);
      notify("Your message has been sent");
    });
    builder.addCase(sendEmail.rejected, (state, action) => {
      console.log("action.payload", action.payload);
      state.loading = false;
      state.sended = false;
    });
    builder.addCase(newsletterAPI.pending, (state) => {
      console.log("state.loading", state.loading);
      state.loading = true;
    });
    builder.addCase(newsletterAPI.fulfilled, (state, action) => {
      console.log("state.loading", state.loading);
      state.loading = false;
      state.newsletter = action.payload;
      notify("Subscribed to newsletter");
    });
    builder.addCase(newsletterAPI.rejected, (state, action) => {
      console.log("state.loading", state.loading);
      state.loading = false;
      state.newsletter = action.payload;
    });
  },
});

export default contactSlice.reducer;
