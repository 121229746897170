import React from "react";

type Props = {} & React.HTMLAttributes<HTMLDivElement>;

const Banner = ({ ...rest }: Props) => {
  return (
    <section className="relative flex flex-row md:pr-40" {...rest}>
      <div className="absolute z-0 md:-translate-y-[107px]">
        <img
          className="object-contain"
          src="/img/banner/bannerLayer1.png"
          alt=""
        />
      </div>
      <div className="absolute z-10">
        <img
          className="object-contain"
          src="/img/banner/bannerLayer2.png"
          alt=""
        />
      </div>

      <div className="w-1/2 flex place-content-center md:place-content-end place-items-center z-20">
        <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-[70px] text-dark-blue font-bold leading-none md:mr-4 md:-translate-y-16">
          Your One-Stop <br /> Fragrance <br /> Destination
        </h1>
      </div>
      <div className="w-60 flex md:w-1/2 z-20">
        <img
          className="object-contain"
          src="/img/banner/bannerLayer3.png"
          alt=""
        />
      </div>
    </section>
  );
};

export default Banner;
