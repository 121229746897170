import React from 'react'

type Props = {
  brand: string
  width: string
  height: string
}

const BrandItem = ({ brand, width, height }: Props) => {
  return (
    <div className="bg-gray w-full h-[156px] flex">
      <img className={`m-auto ${width}  ${height}`} src={`/img/brands/${brand}.png`} alt="" />
    </div>
  )
}

export default BrandItem