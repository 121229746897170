import React from 'react'
import ScrollToSection from '../../../utils/ScrollToSection'
type Props = {

} & React.HTMLAttributes<HTMLDivElement>;

const Layer3 = ({ ...rest }: Props) => {
    return (
        <section className="relative mt-24" {...rest}>
            <div className="absolute z-0">
                <img src="/img/layers/layer3/layer3.png" alt="" />
            </div>
            <div className="responsive grid lg:grid-cols-2 gap-8">
                <div className="col-span-1 flex flex-col order-2 lg:order-1 pt-20">
                    <h3 className='text-lg lg:text-[40px] text-dark-blue font-semibold leading-10'>Strong product catalog for your needs</h3>
                    <p className="text-md text-black mt-6">
                        From popular mainstream fragrances to niche and
                        exclusive collections, our stocklist ensures that you have
                        access to a wide range of choices that will captivate your
                        customers. We constantly update our inventory to stay on
                        top of the latest fragrance trends, ensuring that you can
                        offer the most sought-after scents in the market.
                    </p>
                    <a href='#faq' onClick={ScrollToSection('faq')}
                        className="bg-blue rounded-22 h-[46px] w-[153px] text-white mt-4 hover:bg-orange z-30 flex hover:cursor-pointer">
                        <p className="m-auto">Go to FAQ</p>
                    </a>
                </div>
                <div className="col-span-1 order-1 lg:order-2">
                    <img src="/img/layers/layer3/catalog.png" alt="" />
                </div>
            </div>
        </section>
    )
}

export default Layer3