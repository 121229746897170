import React from "react";
import faq from "../../constants/faq";
import FaqItem from "../basics/FaqItem";

type Props = {} & React.HTMLAttributes<HTMLDivElement>;

const Faq = ({ ...rest }: Props) => {
  return (
    <section
      className="relative mt-[100px] pb-[370px] h-[900px] block bg-[#D1E5E7]"
      {...rest}
    >
      <div className="bg-[#F9F9F9] h-40 relative">
        <h2 className="text-lg lg:text-[42px] font-semibold text-dark-blue text-center">
          Frequently asked questions
        </h2>
        <img
          className="absolute w-40 h-40  lg:w-[330px] lg:h-[333px] left-4 xl:left-32  xl:-translate-y-28"
          src="/img/faq/man.png"
          alt=""
        />
        <img
          className="absolute w-40 h-40 lg:w-[311px] lg:h-[313px] right-4 xl:right-32 xl:-translate-y-32"
          src="/img/faq/woman.png"
          alt=""
        />
      </div>
      <div className="absolute -z-10 bg-[#D1E5E7] w-full">
        <img className="h-[712px]" src="/img/faq/faqbg.png" alt="" />
      </div>
      <div className="flex flex-col w-11/12 lg:w-5/12 mx-auto z-20 translate-y-20 max-h-[600px] pr-5 overflow-y-auto">
        {faq.map((item, index) => (
          <FaqItem
            key={index}
            id={item?.id}
            question={item?.question}
            answer={item?.answer}
          />
        ))}
      </div>
    </section>
  );
};

export default Faq;
