const ScrollToSection = (sectionId?: string) => {
  return () => {
    //@ts-ignore
    const section = document.getElementById(sectionId);
    if (section) {
      const { top } = section.getBoundingClientRect();
      const targetYOffset = top + window.scrollY;

      const startTime = performance.now();
      const duration = 300; // Kaydırma süresi (ms)

      const scrollStep = (timestamp: number) => {
        const currentTime = timestamp - startTime;
        if (currentTime >= duration) {
          window.scrollTo(0, targetYOffset - 200); // 200 piksel üstüne kaydır
          return;
        }

        const progress = currentTime / duration;
        const easeInOutProgress = 0.5 - 0.5 * Math.cos(progress * Math.PI);
        const newScrollY =
          window.scrollY + easeInOutProgress * (targetYOffset - window.scrollY);

        window.scrollTo(0, newScrollY);

        requestAnimationFrame(scrollStep);
      };

      requestAnimationFrame(scrollStep);
    }
  };
};

export default ScrollToSection;
