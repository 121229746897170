import React from 'react'
import Footer from '../sections/Footer';
import Navbar from '../sections/Navbar';
import { act } from '@testing-library/react';
type Props = {
    children: React.ReactNode;
}

const Page = ({ children }: Props) => {
    return (
        <>
            <Navbar />
            {children}
            <Footer />
        </>
    )
}

export default Page;