import React from 'react'

type Props = {
    svg: any;
    title: string;
    description: string;
}

const Layer1Item = ({ svg, title, description }: Props) => {
    return (
        <div className="flex flex-col gap-4 text-black shadow-primary rounded-10 p-2 2xl:p-8">
            <div className="w-full 2xl:w-[165px] lg:h-[143px] mx-auto">
                {svg}
            </div>
            <h3 className='text-center text-[30px] font-semibold leading-10 mt-4'>
                {title.split(' ').map((word, index) => (
                    <span key={index}>
                        {word}
                        <br />
                    </span>
                ))}
            </h3>
            <p className="text-center text-md mt-4">{description}</p>
        </div>
    )
}

export default Layer1Item;